export const DOCUMENT_TYPE = [
    { code: 1, description: "Pessoa Física" },
    { code: 2, description: "Pessoa Jurídica" },
    { code: 3, description: "Estrangeiro" },
    { code: 4, description: "Produtor Rural" }
];

export const DOCUMENT_TYPE_CODES = {
    physical: 1,
    juridic: 2,
    foreign: 3,
    rural: 4
}
